import { FC, useEffect, useState } from 'react';
import { Route, useHistory } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { observer } from 'mobx-react-lite';
import { toJS } from 'mobx';

import { notification, Result, Table } from 'antd';

import styles from './Patient.module.scss';

import { DCInfo, Loader, Nav, StudyFile } from '../../components';
import useStore from '../../hooks/useStore';
import { LoadingStatus } from '../../store/types';
import { FilesApi } from '../../api/files';
import { downloadFile } from '../../utils/commonHelpers';
import { StudyRows } from './components';
import { baseurl } from '../../api/baseurl';
import { IPatientStudy, IStudyDownload } from '../../store/patient';

const columns = [
  {
    title: 'ПІБ',
    dataIndex: 'name',
    key: 'name',
    render: (text: string) => <strong>{text}</strong>,
  },
  {
    title: 'Дата народження',
    dataIndex: 'birthDate',
    key: 'birthDate',
    render: (text: string) => <strong>{text}</strong>,
  },
  {
    title: 'Телефон',
    dataIndex: 'phone',
    key: 'phone',
    render: (text: string) => <strong>{text}</strong>,
  },
];

const Patient: FC = () => {
  const history = useHistory();

  const { patientStore, cashFiles } = useStore();

  const [downloadFileLoading, setDownloadFileLoading] = useState<string | null>(
    null
  );

  useEffect(() => {
    (async () => {
      await patientStore.getPatient();
    })();
  }, []);

  const handleFileShow = async (studyUUID: string, fileUUID: string) => {
    history.push(`/studies/${studyUUID}/file/${fileUUID}`);
  };

  const handleFilePrepare = async (studyUUID: string) => {
    await patientStore.buildStudyImage(studyUUID);
  };

  const handleFileDownload = async (
    studyName: string,
    studyUUID: string,
    download: IStudyDownload
  ) => {
    try {
      if (download.storage === 'local') {
        window.location.href = baseurl + '/studies/local/' + download.fileUUID;
        return;
      }
      setDownloadFileLoading(download.fileUUID);
      let file;
      file = cashFiles.getFile(download.fileUUID);
      if (file) {
        downloadFile(file.blobUrl, file.name);
        return;
      }
      const { url, filename } = await FilesApi.getFileById(
        studyUUID,
        download.fileUUID
      );
      cashFiles.addFile({
        serverUrl: download.fileUUID,
        blobUrl: url,
        name: filename,
      });
      downloadFile(url, filename);
    } catch (e) {
      notification.error({
        message: 'Файл недоступний, зверніться до діагностичного центру',
      });
    } finally {
      setDownloadFileLoading(null);
    }
  };

  const checkStudyImage = async (study: IPatientStudy) => {
    const isoFile = study.downloads.find(
      (download) => download.type === 'Образ диску'
    );
    if (isoFile && isoFile.fileUUID === 'checkStudyImage' && !isoFile.isoStatus) {
      await patientStore.checkFileStatus(study.studyUUID);
    }
  };

  useEffect(() => {
    if (patientStore.patients[0]?.visits[0]) {
      checkStudyImage(patientStore.patients[0].visits[0]);
    }
  }, [patientStore.patients[0]]);

  return (
    <>
      <DCInfo />
      <Nav />
      {patientStore.getPatientsLoadingStatus === LoadingStatus.LOADING ? (
        <Loader />
      ) : (
        <>
          {patientStore.getPatientsLoadingStatus !== LoadingStatus.ERROR &&
          patientStore.patients ? (
            <>
              <Table
                className={styles.table}
                size={isMobile ? 'small' : undefined}
                dataSource={toJS(patientStore.patients).map((s) => ({
                  ...s,
                  key: s.patientID,
                }))}
                expandedRowRender={StudyRows(
                  handleFileShow,
                  handleFileDownload,
                  downloadFileLoading,
                  checkStudyImage,
                  handleFilePrepare
                )}
                defaultExpandedRowKeys={[patientStore.patients[0]?.patientID]}
                expandRowByClick
                columns={columns}
                pagination={false}
              />
            </>
          ) : (
            <Result
              status="error"
              subTitle={patientStore.getPatientError || 'Виникла помилка'}
            />
          )}
        </>
      )}
      <Route
        exact
        path={'/studies/:studyUUID/file/:fileUUID'}
        component={StudyFile}
      />
    </>
  );
};

export default observer(Patient);
