import axios from 'axios';

import { getAuthToken, saveAuthToken } from '../utils/tokenHelpers';
import { AuthApi } from './auth';
import { homeurl } from './baseurl';

axios.interceptors.request.use(async function (config) {
  const querySearchParams = new URLSearchParams(window.location.search);
  const login = querySearchParams.get('login');
  const password = querySearchParams.get('password');

  if (
    login &&
    password &&
    config.url !== homeurl + '/auth' &&
    !config.url?.includes('/dcinfo') &&
    !config.url?.includes('/dclogo')
  ) {
    const encryptedPassword = atob(password);

    const { token, expires } = await AuthApi.login(login, encryptedPassword);
    saveAuthToken(token, expires);

    window.history.pushState(null, '', window.location.pathname);
  }

  const { token } = getAuthToken();

  if (token) {
    config.headers.token = token;
  }

  return config;
});

export default axios;
