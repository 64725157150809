import { FC } from 'react';

import { PhoneOutlined } from '@ant-design/icons';
import { Button } from 'antd';

interface PhoneProps {
  phone: string;
  className?: string;
}

const Phone: FC<PhoneProps> = ({ phone, className }) => {
  return (
    <Button
      className={className}
      href={`tel:${phone}`}
      type="link"
      icon={<PhoneOutlined />}
    >
      {phone}
    </Button>
  );
};

export default Phone;
